import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { supabase } from "../../supabase/client";
import { useEffect, useState } from "react";
import OvalLoader from "../../components/OvalLoader";

const Company = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [companies, setCompany] = useState([]);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      const validateUser = await supabase.auth.getUser();

      if (validateUser.error === null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    async function fetchCompanies() {
      const { data, error } = await supabase
        .from("company")
        .select("id,rut,name,phone_number,email");
      if (error) {
        console.error(error);
      } else {
        setCompany(data);
      }
    }

    checkUserAuth();
    fetchCompanies();
  }, [isAuthenticated]);

  const handleEditClick = (rowId) => {
    navigate(`/modifyCompany/${rowId}`);
  };
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "rut",
      headerName: "Rut",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Nombre",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone_number",
      headerName: "Teléfono",
      flex: 0.5,
      headerAlign: "left",
      align: "left",
    },
    { field: "email", headerName: "Email", flex: 0.5 },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 0.5,
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box display="flex">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              onClick={() => handleEditClick(rowId)}
            >
              Modificar Empresa
            </Button>
          </Box>
        );
      },
    },
  ];
  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header
          title="EMPRESAS"
          subtitle="Listado de empresas asociadas con Lepe y Alamo"
        />
        <Box display="flex" justifyContent="start">
          <Link to="/companyform" style={{ textDecoration: "none" }}>
            <Button type="submit" color="secondary" variant="contained">
              Crear Nueva Empresa
            </Button>
          </Link>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "&. MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.grey[800],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary,
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.grey[800],
            },
          }}
        >
          <DataGrid
            rows={companies}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            sx={{ maxHeight: "100%" }}
          />
        </Box>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};
export default Company;
