import { Oval } from "react-loader-spinner";

const OvalLoader = () => {
  return (
    <div className="center-container">
      <Oval width="400" height="400" ariaLabel="loading" />
    </div>
  );
};
export default OvalLoader;
