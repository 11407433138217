import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { supabase } from "../../supabase/client";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};
let userPrivileges = [];

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [role, setRole] = useState("");

  const menuItems = [
    {
      title: "Inicio",
      to: "/home",
      icon: <HomeOutlinedIcon />,
    },
    {
      title: "Equipo de Trabajo",
      to: "/team",
      icon: <PeopleOutlinedIcon />,
    },
    {
      title: "Cierre de turno",
      to: "/shift",
      icon: <ContactsOutlinedIcon />,
    },
    {
      title: "Empresas",
      to: "/company",
      icon: <ReceiptOutlinedIcon />,
    },
    {
      title: "Punto Venta",
      to: "/sail",
      icon: <PersonOutlinedIcon />,
    },
  ];
  const filteredMenuItems = menuItems.filter((item) => {
    return userPrivileges.includes(item.title);
  });

  useEffect(() => {
    async function fetchRole() {
      const bduser = await supabase.auth.getUser();

      if (bduser.error === null) {
        const person_id = await supabase
          .from("person")
          .select("id")
          .eq("email", bduser.data.user.email);

        const person_role = await supabase
          .from("person_role")
          .select("role_id")
          .eq("person_id", person_id.data[0].id);

        if (person_role.data[0].role_id == 2) {
          userPrivileges = ["Inicio", "Punto Venta"];
        } else {
          userPrivileges = [
            "Inicio",
            "Equipo de Trabajo",
            "Cierre de turno",
            //"Empresas",
            "Punto Venta",
          ];
        }

        setRole(person_role.data[0].role_id);
      } else {
        console.log("else");
      }
    }

    fetchRole();
  }, [role]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.greenAccent[400]} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.greenAccent[400]} !important`,
        },
        minHeight: "100%",
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              ></Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  Combustible
                </Typography>
                <Typography variant="h5" color={colors.grey[200]}>
                  Sistema de Control
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {/* Iterar sobre los elementos filtrados */}
            {filteredMenuItems.map((item) => (
              <Item
                key={item.title}
                title={item.title}
                to={item.to}
                icon={item.icon}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
