import {
  Box,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import SubHeader from "../../components/SubHeader";
import { supabase } from "../../supabase/client";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate } from "react-router-dom";
import OvalLoader from "../../components/OvalLoader";

const dateRegExp =
  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
const date = new Date();

const checkoutSchema = yup.object().shape({
  close_date: yup
    .string()
    .matches(dateRegExp, "el formato de fecha debe ser dd/mm/aaaa")
    .required("requerido"),
  received_liters1: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
  delivered_liters1: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
  received_liters2: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
  delivered_liters2: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
  liter_value: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
  total_liters: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
  total_value: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
});

const initialValues = {
  user: "",
  close_date: date.toLocaleDateString("cl-CL"),
  received_liters1: 0,
  delivered_liters1: 0,
  received_liters2: 0,
  delivered_liters2: 0,
  liter_value: 0,
  total_liters: 0,
  total_value: 0,
};

const ShiftClose = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isla, setIsla] = useState("1");
  const [shift, setShift] = useState("1");
  const [user, setUser] = useState("");
  const [receivedLiters, setReceivedLiters] = useState(0);
  const [deliveredLiters, setDeliveredLiters] = useState(0);
  const [literValue, setLiterValue] = useState(0);
  const [totalLiters, setTotalLiters] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleForIslaChange = (event) => {
    setIsla(event.target.value);
  };
  const handleForShiftChange = (event) => {
    setShift(event.target.value);
  };
  const handleReceivedLitersChange = (event) => {
    setReceivedLiters(event.target.value);
  };
  const handleDeliveredLitersChange = (event) => {
    setDeliveredLiters(event.target.value);
  };
  const handleLiterValueChange = (event) => {
    setLiterValue(event.target.value);
  };
  const handleFormSubmit = async (values) => {
    const insertShift = await supabase.from("shift").insert([
      {
        island: isla,
        shift_number: shift,
        user: user,
        shift_date: values.close_date,
        received_liters1: values.received_liters,
        delivered_liters1: values.delivered_liters,
        received_liters2: values.received_liters,
        delivered_liters2: values.delivered_liters,
        liter_value: values.liter_value,
        total_liters: totalLiters,
        total_sail: totalValue,
      },
    ]);

    if (insertShift.error === null) {
      toast.success("¡Turno Cerrado Correctamente!");
    } else {
      toast.error("Problema al cerrar el turno, por favor intente nuevamente");
    }
  };

  useEffect(() => {
    setTotalLiters(receivedLiters - deliveredLiters);
    setTotalValue((receivedLiters - deliveredLiters) * literValue);

    async function fetchData() {
      const bduser = await supabase.auth.getUser();

      if (bduser.error === null) {
        setUser(bduser.data.user.email);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [receivedLiters, deliveredLiters, literValue, user]);

  const ClickOpenShift = async () => {
    if (window.confirm("¿ESTÁ SEGURO QUE DESEA ABRIR EL TURNO?")) {
      console.log("Acepta");
    } else {
      console.log("Rechaza");
    }
  };

  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header title="CIERRE DE TURNO" />
        <div>
          <Toaster />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <SubHeader subtitle="APERTURA DE TURNO" />
                <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Seleccione Isla
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Seleccione_Isla"
                    labelId="demo-simple-select-filled-label"
                    id="isla"
                    name="isla"
                    onChange={handleForIslaChange}
                    value={isla}
                  >
                    <MenuItem value={1}>Isla 1</MenuItem>
                    <MenuItem value={2}>Isla 2</MenuItem>
                    <MenuItem value={3}>Isla 3</MenuItem>
                    <MenuItem value={4}>Isla 4</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Seleccione Turno
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Seleccione_Turno"
                    labelId="demo-simple-select-filled-label"
                    id="shift"
                    name="shift"
                    onChange={handleForShiftChange}
                    value={shift}
                  >
                    <MenuItem value={1}>Turno 1</MenuItem>
                    <MenuItem value={2}>Turno 2</MenuItem>
                    <MenuItem value={3}>Turno 3</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Usuario"
                  disabled
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={user}
                  name="user"
                  error={!!touched.user && !!errors.user}
                  helperText={touched.user && errors.user}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Fecha Cierre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.close_date}
                  name="close_date"
                  error={!!touched.close_date && !!errors.close_date}
                  helperText={touched.close_date && errors.close_date}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                  <Box mt="1px">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={ClickOpenShift}
                    >
                      Abrir Turno
                    </Button>
                  </Box>
                </FormControl>
                <SubHeader subtitle="REGISTRO DE NUMERALES" />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Numeral Inicial 1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleReceivedLitersChange}
                  value={values.received_liters1}
                  name="received_liters1"
                  error={
                    !!touched.received_liters1 && !!errors.received_liters1
                  }
                  helperText={
                    touched.received_liters1 && errors.received_liters1
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Numeral Final 1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleDeliveredLitersChange}
                  value={values.delivered_liters1}
                  name="delivered_liters1"
                  error={
                    !!touched.delivered_liters1 && !!errors.delivered_liters1
                  }
                  helperText={
                    touched.delivered_liters1 && errors.delivered_liters1
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Numeral Inicial 2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleReceivedLitersChange}
                  value={values.received_liters2}
                  name="received_liters2"
                  error={
                    !!touched.received_liters2 && !!errors.received_liters2
                  }
                  helperText={
                    touched.received_liters2 && errors.received_liters2
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Numeral Final 2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleDeliveredLitersChange}
                  value={values.delivered_liters2}
                  name="delivered_liters2"
                  error={
                    !!touched.delivered_liters2 && !!errors.delivered_liters2
                  }
                  helperText={
                    touched.delivered_liters2 && errors.delivered_liters2
                  }
                  sx={{ gridColumn: "span 2" }}
                />
                <SubHeader subtitle="CÁLCULO DE VENTA" />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="VALOR DEL LITRO"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleLiterValueChange}
                  onKeyDown={handleLiterValueChange}
                  value={values.liter_value}
                  name="liter_value"
                  disabled
                  error={!!touched.liter_value && !!errors.liter_value}
                  helperText={touched.liter_value && errors.liter_value}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="LITROS TOTALES"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={totalLiters}
                  disabled
                  name="total_liters"
                  error={!!touched.total_liters && !!errors.total_liters}
                  helperText={touched.total_liters && errors.total_liters}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="TOTAL VENTA"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={totalValue}
                  disabled
                  name="total_value"
                  error={!!touched.total_value && !!errors.total_value}
                  helperText={touched.total_value && errors.total_value}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Cerrar Turno
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default ShiftClose;
