import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const SubHeader = ({ subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box gridColumn="span 4">
      <Typography variant="h5" color={colors.grey[100]}>
        <b>{subtitle}</b>
      </Typography>
    </Box>
  );
};
export default SubHeader;
