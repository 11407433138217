import {
  Box,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { supabase } from "../../supabase/client";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate } from "react-router-dom";
import OvalLoader from "../../components/OvalLoader";

const phoneRegExp = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;
const dateRegExp =
  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
const date = new Date();

const checkoutSchema = yup.object().shape({
  email: yup.string().email("email invalido").required("requerido"),
  rut: yup.string().required("requerido"),
  name: yup.string().required("requerido"),
  giro: yup.string().required("requerido"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "número debe comenzar con 569")
    .required("requerido"),
  address: yup.string().required("requerido"),
  paydays: yup.number().required("requerido"),
  maxcredit: yup.number().required("requerido"),
});

const initialValues = {
  rut: "",
  email: "",
  name: "",
  giro: "",
  phoneNumber: "",
  address: "",
  paydays: "30",
  maxcredit: "0",
};

const CompanyForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      const validateUser = await supabase.auth.getUser();

      if (validateUser.error === null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkUserAuth();
  }, []);

  const handleFormSubmit = async (values) => {
    const insertCompany = await supabase.from("company").insert([
      {
        rut: values.rut,
        email: values.email,
        name: values.name,
        giro: values.giro,
        phone_number: values.phoneNumber,
        address: values.address,
        paydays: values.paydays,
        maxcredit: values.maxcredit,
      },
    ]);

    if (insertCompany.error === null) {
      toast.success("Empresa registrada correctamente");
    } else {
      toast.error("Error al registrar Empresa");
    }
  };

  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header
          title="CREAR NUEVA EMPRESA"
          subtitle="Crear una nueva empresa como cliente de Lepe y Alamo"
        />
        <div>
          <Toaster />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="RUT"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rut}
                  name="rut"
                  error={!!touched.rut && !!errors.rut}
                  helperText={touched.rut && errors.rut}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Razón Social"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Giro"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.giro}
                  name="giro"
                  error={!!touched.giro && !!errors.giro}
                  helperText={touched.giro && errors.giro}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Número de Contacto"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  name="phoneNumber"
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Dirección"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Monto Máximo de Crédito"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.maxcredit}
                  name="maxcredit"
                  error={!!touched.maxcredit && !!errors.maxcredit}
                  helperText={touched.maxcredit && errors.maxcredit}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Días Plazo Crédito"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.paydays}
                  name="paydays"
                  error={!!touched.paydays && !!errors.paydays}
                  helperText={touched.paydays && errors.paydays}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Crear nueva Empresa
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default CompanyForm;
