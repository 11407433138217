import {
  Box,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { supabase } from "../../supabase/client";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, useParams } from "react-router-dom";
import OvalLoader from "../../components/OvalLoader";

const phoneRegExp = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;
const dateRegExp =
  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
const date = new Date();

let checkoutSchema = yup.object({
  dni: yup.string().required("requerido"),
  firstName: yup.string().required("requerido"),
  middleName: yup.string().required("requerido"),
  lastName: yup.string().required("requerido"),
  phoneNumber: yup
    .string()
    .matches(
      phoneRegExp,
      "número debe comenzar con 569 y tener el largo requerido"
    )
    .required("requerido"),
  birthDate: yup
    .string()
    .matches(dateRegExp, "el formato de fecha debe ser dd/mm/aaaa")
    .required("requerido"),
  address: yup.string().required("requerido"),
});

const ModifyUser = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [role, setRole] = useState("1");
  const [dni, setDNI] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [birthDate, setBirthDate] = useState("");
  const [address, setAddress] = useState("");
  const [buttondisabled, setButtonDisabled] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const formik = useFormikContext();
  const changeRole = (event) => {
    setRole(event.target.value);
  };
  const changeDNI = (event) => {
    setDNI(event.target.value);
    //formik.setFieldValue("dni", event.target.value);
  };
  const changeFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const changeMiddleName = (event) => {
    setMiddleName(event.target.value);
    //formik.setFieldValue("middleName", event.target.value);
  };
  const changeLastName = (event) => {
    setLastName(event.target.value);
    //formik.setFieldValue("lastName", event.target.value);
  };
  const changePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
    //formik.setFieldValue("phoneNumber", event.target.value);
  };
  const changeBirthDate = (event) => {
    setBirthDate(event.target.value);
    //formik.setFieldValue("birthDate", event.target.value);
  };
  const changeAddress = (event) => {
    setAddress(event.target.value);
    //formik.setFieldValue("address", event.target.value);
  };

  useEffect(() => {
    const checkUserAuth = async () => {
      const validateUser = await supabase.auth.getUser();

      if (validateUser.error === null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    async function fetchPersonas() {
      const { data, error } = await supabase
        .from("person")
        .select("*")
        .eq("id", id);

      if (error) {
        console.error(error);
      } else {
        setFirstName(data[0].name);
        setDNI(data[0].dni);
        setMiddleName(data[0].middle_name);
        setLastName(data[0].last_name);
        setPhoneNumber(data[0].phone_number);
        setBirthDate(data[0].birth_date);
        setAddress(data[0].address);
      }
    }
    checkUserAuth();
    fetchPersonas();
  }, [id]);

  const handleFormSubmit = async (values) => {
    const updatePerson = await supabase
      .from("person")
      .update([
        {
          dni: dni,
          name: firstName,
          middle_name: middleName,
          last_name: lastName,
          phone_number: phoneNumber,
          birth_date: birthDate,
          address: address,
        },
      ])
      .eq("id", id);

    if (updatePerson.error === null) {
      const updatePersonRole = await supabase
        .from("person_role")
        .update([{ role_id: parseInt(role) }])
        .eq("person_id", id);

      if (updatePersonRole.error === null) {
        toast.success("Usuario modificado correctamente");
        setButtonDisabled(true);
      } else {
        toast.error("Error al modificar Usuario");
      }
    } else {
      toast.error("Error al modificar Usuario");
    }
  };

  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header
          title="MODIFICAR USUARIO"
          subtitle="Modificar usuario dentro del equipo"
        />
        <div>
          <Toaster />
        </div>
        <Formik
          enableReinitialize
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
          initialValues={{
            dni,
            firstName,
            middleName,
            lastName,
            phoneNumber,
            birthDate,
            address,
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="DNI"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeDNI(e);
                    setFieldValue("dni", e.target.value);
                    handleChange();
                  }}
                  value={dni}
                  name="dni"
                  error={!!touched.dni && !!errors.dni}
                  helperText={touched.dni && errors.dni}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nombre"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeFirstName(e);
                    setFieldValue("firstName", e.target.value);
                    handleChange();
                  }}
                  value={firstName}
                  name="firstName"
                  error={!!touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Segundo Nombre"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeMiddleName(e);
                    setFieldValue("middleName", e.target.value);
                    handleChange();
                  }}
                  value={middleName}
                  name="middleName"
                  error={!!touched.middleName && !!errors.middleName}
                  helperText={touched.middleName && errors.middleName}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Apellidos"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeLastName(e);
                    setFieldValue("lastName", e.target.value);
                    handleChange();
                  }}
                  value={lastName}
                  name="lastName"
                  error={!!touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Número de Contacto"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changePhoneNumber(e);
                    setFieldValue("phoneNumber", e.target.value);
                    handleChange();
                  }}
                  value={phoneNumber}
                  name="phoneNumber"
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Fecha de Nacimiento"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeBirthDate(e);
                    setFieldValue("birthDate", e.target.value);
                    handleChange();
                  }}
                  value={birthDate}
                  name="birthDate"
                  error={!!touched.birthDate && !!errors.birthDate}
                  helperText={touched.birthDate && errors.birthDate}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Dirección"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeAddress(e);
                    setFieldValue("address", e.target.value);
                    handleChange();
                  }}
                  value={address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 4" }}
                />
                <FormControl variant="filled" sx={{ gridColumn: "span 4" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Rol Usuario
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Rol Usuario"
                    labelId="demo-simple-select-filled-label"
                    id="role"
                    name="role"
                    onChange={changeRole}
                    value={role}
                  >
                    <MenuItem value={1}>Administrador</MenuItem>
                    <MenuItem value={2}>Bombero</MenuItem>
                    <MenuItem value={3}>Jefe Playa</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={buttondisabled}
                >
                  Modificar Usuario
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default ModifyUser;
