import {
  Box,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Alert,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import SubHeader from "../../components/SubHeader";
import { supabase } from "../../supabase/client";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate } from "react-router-dom";
import OvalLoader from "../../components/OvalLoader";
import emailjs from "@emailjs/browser";
import axios from "axios";

const date = new Date();

const checkoutSchema = yup.object().shape({
  close_date: yup.string().required("requerido"),
  received_liters: yup
    .number()
    .test(
      "is-decimal",
      "Número decimal se debe escribir con punto, no con coma",
      (value) => (value + "").match(/^[0-9]+([.,][0-9]+)?$/)
    ),
  liter_value: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
  total_liters: yup
    .number()
    .test(
      "is-decimal",
      "Número decimal se debe escribir con punto, no con coma",
      (value) => (value + "").match(/^[0-9]+([.,][0-9]+)?$/)
    ),
  company: yup
    .string()
    .trim()
    .matches(
      /^0*(\d{1,8})\-([\dkK])$/,
      "Formato de Rut debe contener guión sin puntos"
    )
    .required(),
  total_value: yup
    .number()
    .typeError("Sólo se pueden ingresar números")
    .required("requerido")
    .min(0, "Sólo se pueden ingresar números sobre 0")
    .integer("Sólo se pueden ingresar números"),
});

const initialValues = {
  user: "",
  close_date:
    date.toLocaleDateString("cl-CL") + " " + date.toLocaleTimeString("cl-CL"),
  received_liters: 0,
  liter_value: 0,
  total_liters: 0,
  total_value: 0,
};

const Sail = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [user, setUser] = useState("");
  const [receivedLiters, setReceivedLiters] = useState(0);
  const [literValue, setLiterValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companystate, setCompany] = useState("");
  const [companyRut, setCompanyRut] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [variantstate, setVariant] = useState(0);
  const [variantlist, setVariantList] = useState([]);
  const [paymentstate, setPayment] = useState(0);
  const [sailtype, setSailType] = useState(0);
  const [paymentlist, setPaymentList] = useState([]);
  const [dieselrgiva, setDieselrgiva] = useState(0);
  const [dieselrg, setDieselrg] = useState(0);
  const [dieselzf, setDieselzf] = useState(0);
  const [kerosenergiva, setKerosenergiva] = useState(0);
  const [kerosenerg, setKerosenerg] = useState(0);
  const [salesCondition, setSalesCondition] = useState(1);

  const [code, setCode] = useState("ABC123aTbjga91");
  const [companyEmail, setCompanyEmail] = useState("d.iraira.dev@gmail.com");
  const [emailParams, setEmailParams] = useState([code, companyEmail]);
  //const [codigovalido, setCodigoValido] = useState(false);
  //const [totalvalido, setTotalValido] = useState(false);
  const [buttondisabled, setButtonDisabled] = useState(true);
  const [emaildisabled, setEmailDisabled] = useState(true);
  const [disabledList, setDisabledList] = useState([
    false,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const handleReceivedLitersChange = (event) => {
    setReceivedLiters(event.target.value);
  };

  const handleLiterValueChange = (event) => {
    setLiterValue(event.target.value);
  };
  const handleFormSubmit = async (values) => {
    const dateunix = Math.floor(Date.now() / 1000);
    let days = 30;
    if (paymentstate == 13) {
      days = 15;
    } else {
      days = 30;
    }
    const futureDate = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expirationdateunix = Math.floor(futureDate.getTime() / 1000);

    let total_neto = 0;
    let taxes = [];
    let numerodefactura = 0;
    let v_access_token = "";
    if (variantstate == 6 && sailtype == 1) {
      total_neto = dieselrg;
      taxes = [1, 2, 7];
      v_access_token = "a00c9b903daa59f394ec36392fca11e90446ed43";
    } else if (variantstate == 71 && sailtype == 1) {
      total_neto = kerosenerg;
      taxes = [1];
      v_access_token = "a00c9b903daa59f394ec36392fca11e90446ed43";
    }

    if (sailtype == 2) {
      total_neto = totalValue;
      taxes = [];
      v_access_token = "9927f4b503c97cce70f2050e2c250ccea0ef914b";
    }

    const headerSubmit = {
      "Content-Type": "text/json",
      access_token: v_access_token,
    };
    const dataSubmit = {
      documentTypeId: 6,
      officeId: 1,
      emissionDate: dateunix,
      expirationDate: expirationdateunix,
      salesConditionId: salesCondition,
      declareSii: 1, //cambiar por 1
      clientId: companystate,
      sendEmail: 1, //cambiar por 1
      details: [
        {
          variantId: variantstate,
          netUnitValue: total_neto,
          quantity: parseInt(receivedLiters),
          taxId: taxes,
        },
      ],
      payments: [
        {
          paymentTypeId: parseInt(paymentstate),
          amount: totalValue,
          recordDate: dateunix,
        },
      ],
    };

    await axios
      .post("https://api.bsale.cl/v1/documents.json", dataSubmit, {
        headers: headerSubmit,
      })
      .then((response) => {
        console.log(response);
        toast.success("Venta realizada Exitosamente");
        numerodefactura = response.data.number;
        window.open(response.data.urlPublicView, "_blank");
      })
      .catch((error) => {
        toast.error("Error al realizar venta");
        console.log(error);
      });

    const insertSail = await supabase.from("sale").insert([
      {
        user: user,
        sail_type: sailtype,
        variantid: variantstate,
        client: companystate,
        payment_type: paymentstate,
        liters: receivedLiters,
        value: literValue,
        total_value: totalValue,
        num_factura: numerodefactura,
      },
    ]);
    if (insertSail.error === null) {
      setDisabledList([true, true, true, true, true, true, true]);
    } else {
      toast.error("Error al realizar venta");
    }
  };

  const sendEmail = () => {
    emailjs
      .send(
        "service_tr7k66l",
        "template_klk1p7i",
        emailParams,
        "oM45FtRgJqayVgdoJ"
      )
      .then(
        (response) => {
          console.log("Correo enviado!", response.status, response.text);
        },
        (error) => {
          console.error("Error al enviar el correo:", error);
        }
      );
  };
  useEffect(() => {
    let multiplica = receivedLiters * literValue;
    setTotalValue(multiplica.toFixed());

    async function fetchData() {
      const bduser = await supabase.auth.getUser();

      if (bduser.error === null) {
        setUser(bduser.data.user.email);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    }

    async function fetchProduct() {
      let { data: bdvariant, error } = await supabase
        .from("variant")
        .select("id,description");
      if (error) {
        console.error(error);
      } else {
        setVariantList(bdvariant);
        setVariant(variantstate);
      }
    }

    async function fetchPaymentType() {
      let { data: bdpayment, error } = await supabase
        .from("payment_type")
        .select("id,name");
      if (error) {
        console.error(error);
      } else {
        setPaymentList(bdpayment);
        setPayment(paymentstate);
      }
    }

    async function fetchVariantPrice() {
      axios({
        url: "https://api.bsale.cl/v1/price_lists/1/details.json?variantid=6",
        method: "get",
        headers: {
          access_token: "a00c9b903daa59f394ec36392fca11e90446ed43",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setDieselrgiva(response.data.items[0].variantValueWithTaxes);
          setDieselrg(response.data.items[0].variantValue);
        })
        .catch((err) => {
          console.log(err);
        });

      axios({
        url: "https://api.bsale.cl/v1/price_lists/1/details.json?variantid=71",
        method: "get",
        headers: {
          access_token: "a00c9b903daa59f394ec36392fca11e90446ed43",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setKerosenergiva(response.data.items[0].variantValueWithTaxes);
          setKerosenerg(response.data.items[0].variantValue);
        })
        .catch((err) => {
          console.log(err);
        });

      axios({
        url: "https://api.bsale.cl/v1/price_lists/6/details.json?variantid=37677",
        method: "get",
        headers: {
          access_token: "9927f4b503c97cce70f2050e2c250ccea0ef914b",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setDieselzf(response.data.items[0].variantValueWithTaxes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchVariantPrice();
    fetchPaymentType();
    fetchProduct();
    fetchData();
  }, [receivedLiters, literValue, user, buttondisabled, emaildisabled]);

  const handleForChangeVariant = async (event) => {
    setVariant(event.target.value);
    let selectedvariant = parseInt(event.target.value);
    setDisabledList([true, true, false, true, true, true, true]);
    //si es regimen general
    if (sailtype == 1) {
      //si es diesel
      if (selectedvariant == 6) {
        setLiterValue(dieselrgiva);
      } else {
        setLiterValue(kerosenergiva);
      }
    } else if (sailtype == 2) {
      if (selectedvariant == 6) {
        setLiterValue(dieselzf);
      } else {
        setLiterValue(kerosenergiva);
      }
    }
  };

  const handleForSailType = async (event) => {
    setSailType(event.target.value);
    setDisabledList([true, false, true, true, true, true, true]);
    if (event.target.value == 2) {
      setVariant(37677);
      setLiterValue(dieselzf);
      setDisabledList([true, true, false, true, true, true, true]);
    }
  };

  const handleForChangePayment = (event) => {
    setPayment(event.target.value);
    if (event.target.value == 4) {
      setSalesCondition(2);
    } else if (event.target.value == 13) {
      setSalesCondition(3);
    }
    setDisabledList([true, true, true, true, true, false, false]);
  };

  const handleForChangeCompany = (event) => {
    setCompanyRut(event.target.value);
  };

  const getLastSunday = () => {
    const today = new Date();
    const sunday = today.getDate() - today.getDay();
    const sundayDate = new Date(today.setDate(sunday));
    const formattedDate = `${sundayDate.getDate()}/${
      sundayDate.getMonth() + 1
    }/${sundayDate.getFullYear()}`;
    // Ajustar el formato de fecha
    const parts = formattedDate.split("/");
    const adjustedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    // Convertir adjustedDate a Unix Date
    const unixDate = Math.floor(Date.parse(adjustedDate) / 1000);

    return unixDate;
  };

  const handleCompanyBlur = (event) => {
    let selectedCompanyCode = event.target.value;
    let max_credit = "";
    let company_access_token = "";
    let companyid = 0;

    if (sailtype == 1) {
      company_access_token = "a00c9b903daa59f394ec36392fca11e90446ed43";
    } else if (sailtype == 2) {
      company_access_token = "9927f4b503c97cce70f2050e2c250ccea0ef914b";
    }

    axios({
      url: "https://api.bsale.io/v1/clients.json?code=" + selectedCompanyCode,
      method: "get",
      headers: {
        access_token: company_access_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.count == 0) {
          toast.error("Rut Invalido. Intente nuevamente.");
        } else {
          max_credit = response.data.items[0].maxCredit;
          companyid = response.data.items[0].id;
          setCompany(companyid);
          setCompanyName(response.data.items[0].company);
          //setCode(getLastSunday().toString() + companyid.toString()); //CAMBIAR ESTO AL PASAR A PRODUCCIÓN!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          setCode("ABC");
          setCompanyEmail(response.data.items[0].email);
          setEmailParams(
            getLastSunday().toString() + companyid.toString(),
            response.data.items[0].email
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios({
      url:
        "https://api.bsale.io/v1/clients/unpaid_documents.json?code=" +
        selectedCompanyCode,
      method: "get",
      headers: {
        access_token: company_access_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let getCompany = response.data.client.company;
        let getOverdueDebt = response.data.overdueDebt;
        let getTotalDebt = response.data.totalDebt;
        if (getOverdueDebt > 0) {
          toast.error(
            "El cliente " +
              getCompany +
              " posee deuda vencida por un total de " +
              getOverdueDebt +
              " pesos. Antes de comprar debe regularizar deuda.",
            {
              duration: 15000,
            }
          );
        } else if (
          getOverdueDebt === 0 &&
          parseInt(getTotalDebt) >= parseInt(max_credit)
        ) {
          toast.error(
            "El cliente " +
              getCompany +
              " no cuenta con credito disponible. Antes de comprar debe regularizar deuda.",
            {
              duration: 15000,
            }
          );
        } else {
          toast.success(
            "El cliente " +
              getCompany +
              " cuenta con " +
              (parseInt(max_credit) - parseInt(getTotalDebt)) +
              " de crédito disponible para realizar compras.",
            {
              duration: 15000,
            }
          );
          setCompany(event.target.value);
          setDisabledList([true, true, true, false, true, true, true]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlurCode = async (event) => {
    let blurcode = event.target.value;
    if (blurcode === code) {
      if (sailtype == 2) {
        let { data: bdpayment, error } = await supabase
          .from("payment_type")
          .select("id,name")
          .neq("id", 13);
        if (error) {
          console.error(error);
        } else {
          setPaymentList(bdpayment);
          setPayment(paymentstate);
        }
      }
      toast.success("El código ingresado es correcto");
      setDisabledList([true, true, true, true, false, true, true]);
    } else {
      toast.error(
        "El código ingresado no coincide. Verifica mayusculas y minusculas"
      );
    }
  };

  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header title="Punto de Venta" />
        <div>
          <Toaster />
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <SubHeader subtitle="INGRESO DE VENTA" />
                <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Tipo de venta
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Tipo de venta"
                    labelId="demo-simple-select-filled-label"
                    id="sailtype"
                    name="sailtype"
                    onChange={handleForSailType}
                    disabled={disabledList[0]}
                    sx={{ gridColumn: "span 2" }}
                    value={sailtype}
                  >
                    <MenuItem value={0}>Seleccione Tipo de Venta</MenuItem>
                    <MenuItem value={1}>Regimen General</MenuItem>
                    <MenuItem value={2}>Zona Franca</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Producto
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Producto"
                    labelId="demo-simple-select-filled-label"
                    id="variant"
                    name="variant"
                    disabled={disabledList[1]}
                    onChange={handleForChangeVariant}
                    sx={{ gridColumn: "span 2" }}
                    value={variantstate}
                  >
                    <MenuItem value={0}>Seleccione un Producto</MenuItem>
                    {variantlist.map((variant) => (
                      <MenuItem
                        key={parseInt(variant.id)}
                        value={parseInt(variant.id)}
                      >
                        {variant.description}
                      </MenuItem>
                    ))}
                    <MenuItem value={37677} style={{ display: "none" }}>
                      Diesel
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Rut Empresa (sin puntos)"
                  disabled={disabledList[2]}
                  onBlur={handleCompanyBlur}
                  onChange={handleForChangeCompany}
                  value={companyRut}
                  name="company"
                  error={!!touched.company && !!errors.company}
                  helperText={touched.company && errors.company}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="EMPRESA"
                  disabled
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={companyname}
                  name="companyname"
                  error={!!touched.companyname && !!errors.companyname}
                  helperText={touched.companyname && errors.companyname}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormControl variant="filled" sx={{ gridColumn: "span 1" }}>
                  <Box mt="20px">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={sendEmail}
                      disabled={disabledList[3]}
                    >
                      Reenviar Código de verificación
                    </Button>
                  </Box>
                </FormControl>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Código"
                  disabled={disabledList[3]}
                  onChange={handleChange}
                  onBlur={handleBlurCode}
                  value={values.validcode}
                  name="validcode"
                  error={!!touched.validcode && !!errors.validcode}
                  helperText={touched.validcode && errors.validcode}
                  sx={{ gridColumn: "span 1" }}
                />
                <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Medio de Pago
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Medio de Pago"
                    labelId="demo-simple-select-filled-label"
                    id="payment"
                    name="payment"
                    disabled={disabledList[4]}
                    onChange={handleForChangePayment}
                    sx={{ gridColumn: "span 2" }}
                    value={paymentstate}
                  >
                    <MenuItem value={0}>Seleccione Medio de Pago</MenuItem>
                    {paymentlist.map((payment) => (
                      <MenuItem
                        key={parseInt(payment.id)}
                        value={parseInt(payment.id)}
                      >
                        {payment.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="USUARIO"
                  disabled
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={user}
                  name="user"
                  error={!!touched.user && !!errors.user}
                  helperText={touched.user && errors.user}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="FECHA VENTA"
                  disabled
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.close_date}
                  name="close_date"
                  error={!!touched.close_date && !!errors.close_date}
                  helperText={touched.close_date && errors.close_date}
                  sx={{ gridColumn: "span 2" }}
                />
                <SubHeader subtitle="REGISTRO DE VENTA" />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Litros Vendidos"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disabledList[5]}
                  onKeyUp={handleReceivedLitersChange}
                  value={values.received_liters}
                  name="received_liters"
                  error={!!touched.received_liters && !!errors.received_liters}
                  helperText={touched.received_liters && errors.received_liters}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="VALOR DEL LITRO"
                  disabled
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onKeyUp={handleLiterValueChange}
                  onKeyDown={handleLiterValueChange}
                  value={literValue}
                  name="liter_value"
                  error={!!touched.liter_value && !!errors.liter_value}
                  helperText={touched.liter_value && errors.liter_value}
                  sx={{ gridColumn: "span 2" }}
                />
                <SubHeader subtitle="CÁLCULO DE VENTA" />
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="TOTAL VENTA"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={totalValue}
                  disabled
                  name="total_value"
                  error={!!touched.total_value && !!errors.total_value}
                  helperText={touched.total_value && errors.total_value}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={handleFormSubmit}
                  disabled={disabledList[6]}
                >
                  Confirmar pago
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default Sail;
