import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./scenes/auth/Login";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import UserForm from "./scenes/team/createUser";
import ModifyUser from "./scenes/team/modifyUser";
import ShiftClose from "./scenes/shift";
import Company from "./scenes/company";
import CompanyForm from "./scenes/company/createCompany";
import ModifyCompany from "./scenes/company/modifyCompany";
import Sail from "./scenes/sail";
import Sidebar from "./scenes/global/Sidebar";
import RecoverPassword from "./scenes/auth/RecoverPassword";
import { useState } from "react";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const showTopbar = location.pathname !== "/";
  const showSidebar = location.pathname !== "/";
  const showTopbarpsw = location.pathname !== "/RecoverPassword";
  const showSidebarpsw = location.pathname !== "/RecoverPassword";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {showSidebar && showSidebarpsw && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {showTopbar && showTopbarpsw && (
              <Topbar setIsSidebar={setIsSidebar} />
            )}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/RecoverPassword" element={<RecoverPassword />} />
              <Route path="/home" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/userform" element={<UserForm />} />
              <Route path="/modifyUser/:id" element={<ModifyUser />} />
              <Route path="/shift" element={<ShiftClose />} />
              <Route path="/company" element={<Company />} />
              <Route path="/companyform" element={<CompanyForm />} />
              <Route path="/modifyCompany/:id" element={<ModifyCompany />} />
              <Route path="/sail" element={<Sail />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
