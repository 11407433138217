import { Box } from "@mui/material";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { supabase } from "../../supabase/client";
import { Navigate } from "react-router-dom";
import OvalLoader from "../../components/OvalLoader";

const Dashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      const validateUser = await supabase.auth.getUser();

      if (validateUser.error === null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    checkUserAuth();
  }, []);

  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header
            title="Dashboard"
            subtitle="Aquí iran los diferentes gráficos asociados al negocio"
          ></Header>
        </Box>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};
export default Dashboard;
