import {
  Box,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { supabase } from "../../supabase/client";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate } from "react-router-dom";
import OvalLoader from "../../components/OvalLoader";

const phoneRegExp = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;
const dateRegExp =
  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
const passRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
const date = new Date();

const checkoutSchema = yup.object().shape({
  email: yup.string().email("email invalido").required("requerido"),
  password: yup
    .string()
    .matches(
      passRegExp,
      "debe contener al menos mayúsculas, minúsculas y números"
    )
    .required("requerido"),
  dni: yup.string().required("requerido"),
  firstName: yup.string().required("requerido"),
  middleName: yup.string().required("requerido"),
  lastName: yup.string().required("requerido"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "número debe comenzar con 569")
    .required("requerido"),
  birthDate: yup
    .string()
    .matches(dateRegExp, "el formato de fecha debe ser dd/mm/aaaa")
    .required("requerido"),
  address: yup.string().required("requerido"),
});

const initialValues = {
  email: "",
  password: "",
  dni: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  birthDate: "",
  address: "",
};

const UserForm = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [rolestate, setRole] = useState(0);
  const [rolelist, setRoleList] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      const validateUser = await supabase.auth.getUser();

      if (validateUser.error === null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    async function fetchRole() {
      let { data: bdrole, error } = await supabase.from("role").select("*");

      if (error) {
        console.error(error);
      } else {
        setRoleList(bdrole);
        setRole(bdrole[0].id);
      }
    }
    checkUserAuth();
    fetchRole();
  }, []);

  const handleForChange = (event) => {
    console.log(event);
    setRole(event.target.value);
  };

  const handleFormSubmit = async (values) => {
    const insertUser = await supabase.auth.signUp({
      email: values.email,
      password: values.password,
    });
    if (insertUser.error === null) {
      const insertPerson = await supabase.from("person").insert([
        {
          dni: values.dni,
          name: values.firstName,
          middle_name: values.middleName,
          last_name: values.lastName,
          phone_number: values.phoneNumber,
          birth_date: values.birthDate,
          address: values.address,
          hire_date: date.toLocaleDateString("cl-CL").toString(),
          email: values.email,
        },
      ]);

      if (insertPerson.error === null) {
        const person_id = await supabase
          .from("person")
          .select("id")
          .eq("email", values.email);

        if (person_id.error === null) {
          const insertPersonRole = await supabase.from("person_role").insert([
            {
              person_id: parseInt(person_id.data[0].id),
              role_id: parseInt(rolestate),
            },
          ]);
          if (insertPersonRole.error === null) {
            toast.success("Usuario registrado correctamente");
          } else {
            toast.error("Error al registrar Usuario");
          }
        } else {
          toast.error("Error al registrar Usuario");
        }
      } else {
        toast.error("Error al registrar Usuario");
      }
    } else {
      toast.error("Error al registrar usuario");
    }
  };

  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header
          title="CREAR NUEVO USUARIO"
          subtitle="Crear un nuevo usuario dentro del equipo"
        />
        <div>
          <Toaster />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Contraseña"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="DNI"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dni}
                  name="dni"
                  error={!!touched.dni && !!errors.dni}
                  helperText={touched.dni && errors.dni}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nombre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={!!touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Segundo Nombre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.middleName}
                  name="middleName"
                  error={!!touched.middleName && !!errors.middleName}
                  helperText={touched.middleName && errors.middleName}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Apellidos"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={!!touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Número de Contacto"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phoneNumber}
                  name="phoneNumber"
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Fecha de Nacimiento"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.birthDate}
                  name="birthDate"
                  error={!!touched.birthDate && !!errors.birthDate}
                  helperText={touched.birthDate && errors.birthDate}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Dirección"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 4" }}
                />
                <FormControl variant="filled" sx={{ gridColumn: "span 4" }}>
                  <InputLabel id="demo-simple-select-filled-label">
                    Rol Usuario
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Rol Usuario"
                    labelId="demo-simple-select-filled-label"
                    id="role"
                    name="role"
                    onChange={handleForChange}
                    value={rolestate}
                  >
                    {rolelist.map((role) => (
                      <MenuItem
                        key={parseInt(role.id)}
                        value={parseInt(role.id)}
                      >
                        {role.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Crear nuevo Usuario
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default UserForm;
