import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabase/client";
import toast, { Toaster } from "react-hot-toast";
import truckCenter from "../../img/truckCenter.jpeg";

const LoginContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-image: linear-gradient(to right, #dcdcdc, #fff);
  background-size: cover;
  overflow: hidden;
`;

const LoginImg = styled.img`
  margin-bottom: 20px;
  width: 30%;
  height: 10%;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
`;

const InputField = styled.input`
  padding: 10px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 50%;
  font-size: 15px;
`;

const SubmitButton = styled.button`
  background-color: #4cceac;
  color: #000057;
  padding: 10px;
  border: none;
  border-radius: 5px;
  width: 52%;
  font-size: 15px;
  cursor: pointer;
`;

const RecoverPassword = () => {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await supabase.auth.updateUser({
        password: password,
      });
      if (result.error == null) {
        toast.success("Contraseña cambiada correctamente");
        navigate("/");
      } else {
        console.log(result);
        toast.error("Error al cambiar contraseña");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleSubmit}>
        <Toaster />
        <LoginImg src={truckCenter} alt="TruckCenter" />
        <InputField
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={handlePasswordChange}
        />
        <SubmitButton type="submit">Cambiar Contraseña</SubmitButton>
      </LoginForm>
      <Toaster />
    </LoginContainer>
  );
};

export default RecoverPassword;
