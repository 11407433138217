import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { supabase } from "../../supabase/client";
import { useEffect, useState } from "react";
import OvalLoader from "../../components/OvalLoader";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [personas, setPersonas] = useState([]);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      const validateUser = await supabase.auth.getUser();

      if (validateUser.error === null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    async function fetchPersonas() {
      const { data, error } = await supabase
        .from("person")
        .select("id,name,last_name,phone_number,email");
      if (error) {
        console.error(error);
      } else {
        setPersonas(data);
      }
    }

    checkUserAuth();
    fetchPersonas();
  }, [isAuthenticated]);

  const handleEditClick = (rowId) => {
    navigate(`/modifyUser/${rowId}`);
  };
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nombre",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "last_name",
      headerName: "Apellido",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone_number",
      headerName: "Teléfono",
      flex: 0.5,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    { field: "email", headerName: "Email", flex: 0.5 },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 0.5,
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Box display="flex">
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              onClick={() => handleEditClick(rowId)}
            >
              Modificar Usuario
            </Button>
          </Box>
        );
      },
    },
  ];
  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header
          title="EQUIPO DE TRABAJO"
          subtitle="Administración de miembros del equipo"
        />
        <Box display="flex" justifyContent="start">
          <Link to="/userform" style={{ textDecoration: "none" }}>
            <Button type="submit" color="secondary" variant="contained">
              Crear Nuevo Usuario
            </Button>
          </Link>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "&. MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.grey[800],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary,
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.grey[800],
            },
          }}
        >
          <DataGrid
            rows={personas}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            sx={{ maxHeight: "100%" }}
          />
        </Box>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};
export default Team;
