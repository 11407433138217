import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { supabase } from "../../supabase/client";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Navigate, useParams } from "react-router-dom";
import OvalLoader from "../../components/OvalLoader";

const phoneRegExp = /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/;

let checkoutSchema = yup.object().shape({
  rut: yup.string().required("requerido"),
  email: yup.string().required("requerido"),
  name: yup.string().required("requerido"),
  giro: yup.string().required("requerido"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "número debe comenzar con 569")
    .required("requerido"),
  address: yup.string().required("requerido"),
  paydays: yup.number().required("requerido"),
  maxcredit: yup.number().required("requerido"),
});

const ModifyCompany = () => {
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [rut, setRut] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [giro, setGiro] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [paydays, setPaydays] = useState("");
  const [maxcredit, setMaxcredit] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const changeRut = (event) => {
    setRut(event.target.value);
  };
  const changeEmail = (event) => {
    setEmail(event.target.value);
  };
  const changeName = (event) => {
    setName(event.target.value);
  };
  const changeGiro = (event) => {
    setGiro(event.target.value);
  };
  const changePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };
  const changeAddress = (event) => {
    setAddress(event.target.value);
  };
  const changePaydays = (event) => {
    setPaydays(event.target.value);
  };
  const changeMaxCredit = (event) => {
    setMaxcredit(event.target.value);
  };

  useEffect(() => {
    const checkUserAuth = async () => {
      const validateUser = await supabase.auth.getUser();

      if (validateUser.error === null) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    async function fetchCompany() {
      const { data, error } = await supabase
        .from("company")
        .select("*")
        .eq("id", id);

      if (error) {
        console.error(error);
      } else {
        setEmail(data[0].email);
        setRut(data[0].rut);
        setName(data[0].name);
        setGiro(data[0].giro);
        setPhoneNumber(data[0].phone_number);
        setAddress(data[0].address);
        setPaydays(data[0].paydays);
        setMaxcredit(data[0].maxcredit);
      }
    }
    checkUserAuth();
    fetchCompany();
  }, [id]);

  const handleFormSubmit = async (values) => {
    console.log("llegue hermaneke");
    const updateCompany = await supabase
      .from("company")
      .update([
        {
          rut: rut,
          email: email,
          name: name,
          giro: giro,
          phone_number: phoneNumber,
          address: address,
          paydays: paydays,
          maxcredit: maxcredit,
        },
      ])
      .eq("id", id);

    if (updateCompany.error === null) {
      toast.success("Empresa modificada correctamente!");
    } else {
      toast.error("Error al modificar Empresa");
    }
  };

  if (isLoading) {
    return (
      <div>
        <OvalLoader />
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <Box m="20px">
        <Header
          title="MODIFICAR EMPRESA"
          subtitle="Modificar registro de empresa"
        />
        <div>
          <Toaster />
        </div>
        <Formik
          validationSchema={checkoutSchema}
          onSubmit={handleFormSubmit}
          initialValues={{
            rut: rut,
            email: email,
            name: name,
            giro: giro,
            phone_number: phoneNumber,
            address: address,
            paydays: paydays,
            maxcredit: maxcredit,
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="RUT"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeRut(e);
                    setFieldValue("rut", e.target.value);
                    handleChange();
                  }}
                  value={rut}
                  name="rut"
                  error={!!touched.rut && !!errors.rut}
                  helperText={touched.rut && errors.rut}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="EMAIL"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeEmail(e);
                    setFieldValue("email", e.target.value);
                    handleChange();
                  }}
                  value={email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Razón Social"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeName(e);
                    setFieldValue("name", e.target.value);
                    handleChange();
                  }}
                  value={name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Giro"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeGiro(e);
                    setFieldValue("giro", e.target.value);
                    handleChange();
                  }}
                  value={giro}
                  name="giro"
                  error={!!touched.giro && !!errors.giro}
                  helperText={touched.giro && errors.giro}
                  sx={{ gridColumn: "span 4" }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Número de Contacto"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changePhoneNumber(e);
                    setFieldValue("phoneNumber", e.target.value);
                    handleChange();
                  }}
                  value={phoneNumber}
                  name="phoneNumber"
                  error={!!touched.phoneNumber && !!errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Dirección"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeAddress(e);
                    setFieldValue("address", e.target.value);
                    handleChange();
                  }}
                  value={address}
                  name="address"
                  error={!!touched.address && !!errors.address}
                  helperText={touched.address && errors.address}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Días Plazo Crédito"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changePaydays(e);
                    setFieldValue("paydays", e.target.value);
                    handleChange();
                  }}
                  value={paydays}
                  name="paydays"
                  error={!!touched.paydays && !!errors.paydays}
                  helperText={touched.paydays && errors.paydays}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Monto Máximo de Crédito"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    changeMaxCredit(e);
                    setFieldValue("maxcredit", e.target.value);
                    handleChange();
                  }}
                  value={maxcredit}
                  name="maxcredit"
                  error={!!touched.maxcredit && !!errors.maxcredit}
                  helperText={touched.maxcredit && errors.maxcredit}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Modificar Empresa
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default ModifyCompany;
